import { css } from 'styled-components'
import { breakpoints } from './styled-breakpoints'

/* 
    * Media Query Mixin for styled components
   Using mobile-first approach
   
   Can be used in some styled component like so:

    import {forScreen} from "path to styled-mixins.js"
    ...
    const ItemsList = styled.div`

    ${forScreen.sm`
        background-color: blue;
    `}
    ${forScreen.lg`
        background-color: green;
    `}

    .sm .lg and so on come from defined breakpoints

    Technique: https://tobbelindstrom.com/blog/how-to-create-a-breakpoint-mixin-with-styled-components/
    My notes: https://www.notion.so/jurekskowron/Mixiny-media-query-w-styled-components-386d99b49500470782cb311d2084a3ca
*/
export const forScreen = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media screen and (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `
    return accumulator
  },
  {}
)
