import { AiFillFacebook, AiFillInstagram } from 'react-icons/ai'

export default {
  facebook: {
    link: 'https://www.facebook.com/kinostrzelin',
    accessibleText: 'Facebook Kina Grażyna',
    Icon: AiFillFacebook,
  },
  instagram: {
    link: 'https://www.instagram.com/kino_grazyna/',
    accessibleText: 'Instagram Kina Grażyna',
    Icon: AiFillInstagram,
  },
}
