import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Navbar from './navbar'
import InfoBar from './infoBar'

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <h2 className="visually-hidden">Kino Grażyna Strzelin</h2>
    <InfoBar />
    <div className="container-xxl">
      <Navbar />
    </div>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const StyledHeader = styled.header`
  background-color: var(--headerBackground);
  border-bottom: 1px solid var(--headerLineColor);
`

export default Header
