// * Breakpoints for styled components only
// ! for scss there are distinct breakpoints
// ! in /styles-global/global-variables.scss
// ! these should be manually "synchronised" to be the same with scss

// breakpoints are $grid-breakpoint- for scss (bootstrap)
// we can omit xxs from there as not used
export const breakpoints = {
  xs: '376px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1900px',
}
