/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import 'moment/locale/pl'

import Header from './header/header'
import Footer from './footer/footer'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <StyledLayout>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </StyledLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const StyledLayout = styled.div`
  background-color: var(--backgroundMain);
  color: var(--textMainColor);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: var(--headingColor);
  }

  .element-dark {
    color: var(--onDarkColor);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: var(--onDarkHeadingColor);
    }

    & a:not(.btn) {
      color: var(--onDarkLinkColor);

      &:link,
      &:visited {
        color: var(--onDarkLinkColor);
      }
    }
  }

  /* prevent from using 'bolder' set in bootstrap - with bolder there were issues with polish characters  */
  b,
  strong {
    font-weight: bold;
  }

  /* prevent empty space after footer on too short pages */
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > Header,
  & > Footer {
    flex-shrink: 0;
  }

  & > main {
    flex: 1 0 auto;
  }
`
