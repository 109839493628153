import React from 'react'
import Obfuscate from 'react-obfuscate'
import styled from 'styled-components'
import IconButton from '../buttons/IconButton'

const InfoBar = () => (
  <StyledInfoBar className="element-dark">
    <p>Informacje</p>
    <p>
      e-mail: <Obfuscate email="biuro@kinostrzelin.pl" />
    </p>
    <p>
      tel.: <Obfuscate tel="+48 575 519 155" />
    </p>
    <div>
      <IconButton option="facebook" />
      <IconButton option="instagram" />
    </div>
  </StyledInfoBar>
)

const StyledInfoBar = styled.div`
  background-color: var(--backgroundSecond);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding: 1rem;

  /* add separator */
  p {
    text-align: center;
    margin-bottom: 0;

    :not(:last-child) {
      ::after {
        content: '|';
        margin: 0 0.2em;
      }
    }
  }
`

export default InfoBar
