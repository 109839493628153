// temporary workaround because gatsby-node doesn't allow ES6 import
// hence it is required directly in gatsby-node and required in constants/paths.js
// that is then imported i.e. in index

exports.obj = {
  mainNavPages: [
    // main pages visible in navigation
    { path: '/', text: 'Repertuar' },
    { path: '/o-kinie', text: 'O kinie' },
    { path: '/edukacja', text: 'Edukacja' },
    { path: '/dokumenty', text: 'Dokumenty' },
  ],
  repertoire: {
    // path for a repertoire list
    path: '',
  },
  repertoireMovies: {
    // path for an individual movie page (ex. ; /repertuar/the-god-father)
    path: 'repertuar',
  },
  aboutCinema: {
    // path for an aboutCinema page
    path: 'o-kinie',
  },
  documents: {
    // path for an individual document page (ex. ; /dokumenty/regulamin)
    path: 'dokumenty',
  },
  education: {
    // path for an individual education item page (ex. ; /edukacja/kino-szkola)
    path: 'edukacja',
  },
}
