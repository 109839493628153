// withLocation.js

import React from 'react'
import { Location } from '@reach/router'
import queryString from 'query-string'
import moment from 'moment'

/* * COMPONENT WRAPPER FOR QUERY PARAMS
  Component wrapper which allows to handle queryParams in dynamic components
  e.g. http://localhost:8000/?custom=test or http://localhost:8000/?testDay=2020-09-11

  To use it we have to wrap our dynamic component with it, like below:

      -----------------------------------------------------
      import PropTypes from "prop-types"
      import withLocation from "./withLocation"

      const CustomQueryStringComponent = ({ search }) => {
        const { custom } = search
        return <p>Custom Value: {custom}</p>
      }

      CustomQueryStringComponent.propTypes = {
        search: PropTypes.object,
      }

      export default withLocation(CustomQueryStringComponent)
      --------------------------------------------------------
      as you can see above, we can extract this way e.g. 'custom' queryParam
      from e.g. http://localhost:8000/?custom=test

*/

/* TESTDAY QUERPYPARAM VALIDATION
  This component also handles 'special' queryParam 'testDay'
  e.g. http://localhost:8000/?testDay=2020-09-11 

  It validated if given testDay is valid "YYYY-MM-DD" date string
  If so - it passes to a wrapped component testDay as a moment object
  Otherwise it passes false

  It can be used like this on some dynamic component:
      --------------------------------------------------------
      const RepertoireList = ({ testDay }) => {
        ...
      }

      export default withLocation(RepertoireList)
      --------------------------------------------------------
*/

const withLocation = ComponentToWrap => props => (
  <Location>
    {({ location, navigate }) => {
      /* search consists object of queryParams with its values */
      const search = location.search ? queryString.parse(location.search) : {}
      /* validation of testDay */
      const testDay = validateAndReturnTestDay(search)
      return (
        <ComponentToWrap
          {...props}
          location={location}
          navigate={navigate}
          search={search}
          testDay={testDay}
        />
      )
    }}
  </Location>
)

export default withLocation

// validates if passed property testDay with value which is valid date with format "YYYY-MM-DD"
// if so, returns this date (moment)
// if the value is not valid date returns false and shows warning
// if property not given - returns false
const validateAndReturnTestDay = queryParamsObj => {
  if (!queryParamsObj || !queryParamsObj.testDay) {
    return
  }
  const day = moment(queryParamsObj.testDay, 'YYYY-MM-DD', true) // third argument true enforces strict parsing
  if (day.isValid()) {
    return day
  }
  // if testDay given but is not valid date
  console.warn(
    `Received testDay which is not valid date in format "YYYY-MM-DD": ${queryParamsObj.testDay}`
  )
}
