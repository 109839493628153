import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Obfuscate from 'react-obfuscate'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import paths from '../../constants/paths'
import IconButton from '../buttons/IconButton'

import styles from './footer.module.scss'
import FooterReperoireList from './footerReperoireList'

/* 
TODO:
 - get rid of bootstrap 
 - add nav with h2 FooterNavigation
*/

const Footer = () => {
  const {
    allSanityEducation: { nodes: educationMenuItems },
    allSanityDocumentItem: { nodes: documentsMenuItems },
    allSanityFilm: { nodes: allSanityFilm },
    sanityAboutCinema: cinemaPartners,
  } = useStaticQuery(getMenuItems)

  return (
    <footer className={`${styles.footer} element-dark py-5 px-3`}>
      <div className="container-styled-xxl">
        <Row className="mb-4">
          <Col xs={12} lg={4}>
            <p className="h2">Kino Grażyna Strzelin </p>
            <address>
              <span className="block">ul. Adama Mickiewicza 2 </span>
              <span className="block">57-100 Strzelin </span>
              <span className="block">
                <Obfuscate tel="+48 575 519 155" />
              </span>
              <span className="block">
                <Obfuscate email="biuro@kinostrzelin.pl" />
              </span>
            </address>
          </Col>
          <Col xs={12} sm={6} md={3} lg={2}>
            {/* REPERTUAR */}
            <h2>
              <Link to={`/${paths.repertoire.path}`}>Repertuar</Link>
            </h2>
            <FooterReperoireList movies={allSanityFilm} />
          </Col>
          <Col xs={12} sm={6} md={3} lg={2}>
            <h2>
              <Link to={`/${paths.aboutCinema.path}`}>O kinie</Link>
            </h2>
            <ul className={styles.footerList}>
              <li>
                <Link to={`/${paths.aboutCinema.path}#aboutUs`}>
                  Informacje o kinie
                </Link>
              </li>
              <li>
                <Link to={`/${paths.aboutCinema.path}#priceList`}>
                  Cennik biletów
                </Link>
              </li>
              {
                /* Dynamic item - shown if defined partners (if list not empty) */
                cinemaPartners && cinemaPartners.partners.length > 0 && (
                  <li>
                    <Link to={`/${paths.aboutCinema.path}#partners`}>
                      {
                        // if defined - show custom partners title (defined in sanity)
                        cinemaPartners.customPartnersTitle &&
                        cinemaPartners.customPartnersTitle.length > 0
                          ? cinemaPartners.customPartnersTitle
                          : 'Partnerzy'
                      }
                    </Link>
                  </li>
                )
              }
              <li>
                <Link to={`/${paths.aboutCinema.path}#findUs`}>Znajdź nas</Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={6} md={3} lg={2}>
            {/* EDUKACJA */}
            <h2>
              <Link to={`/${paths.education.path}`}>Edukacja</Link>
            </h2>
            <ul className={styles.footerList}>
              {educationMenuItems.map(
                ({ slug, title, showOnMenu: { shortMenuTitle } }) => (
                  <li key={slug.current}>
                    <Link to={`/${paths.education.path}/${slug.current}`}>
                      {shortMenuTitle || title}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </Col>

          <Col xs={12} sm={6} md={3} lg={2}>
            {/* DOKUMENTY */}
            <h2>
              <Link to={`/${paths.documents.path}`}>Dokumenty</Link>
            </h2>
            <ul className={styles.footerList}>
              {documentsMenuItems.map(
                ({ slug, title, showOnMenu: { shortMenuTitle } }) => (
                  <li key={slug.current}>
                    <Link to={`/${paths.documents.path}/${slug.current}`}>
                      {shortMenuTitle || title}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </Col>
        </Row>
        <Row className="justify-center">
          <Col>
            <p className="text-center">
              <IconButton option="facebook" isBigger />
              <IconButton option="instagram" isBigger />
            </p>
          </Col>
        </Row>
        <Row className="justify-center">
          <Col>
            <p className="text-center">
              © Kino Grażyna {new Date().getFullYear()}
            </p>
          </Col>
        </Row>
      </div>
    </footer>
  )
}

const getMenuItems = graphql`
  query {
    allSanityEducation(
      filter: { showOnMenu: { isOnMenu: { eq: true } } }
      sort: { fields: priorityOnList, order: DESC }
    ) {
      nodes {
        showOnMenu {
          isOnMenu
          shortMenuTitle
        }
        title
        slug {
          current
        }
      }
    }
    allSanityDocumentItem(
      filter: { showOnMenu: { isOnMenu: { eq: true } } }
      sort: { fields: priorityOnList, order: DESC }
    ) {
      nodes {
        showOnMenu {
          shortMenuTitle
        }
        title
        slug {
          current
        }
      }
    }
    allSanityFilm(sort: { fields: priorityOnList, order: DESC }) {
      nodes {
        titlePl
        slug {
          current
        }
        priorityOnList
        onScreen {
          startDate(formatString: "DD.MM.YYYY")
          machineEndDate: endDate(formatString: "YYYY-MM-DD")
        }
      }
    }
    sanityAboutCinema(_id: { eq: "aboutCinema" }) {
      customPartnersTitle
      partners {
        title
      }
    }
  }
`

export default Footer
