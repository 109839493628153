import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const getLogo = graphql`
  query {
    file(relativePath: { eq: "logo-kino-grazyna_blue.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const Logo = ({ alt }) => {
  const { file } = useStaticQuery(getLogo)

  return <Img fluid={file.childImageSharp.fluid} alt={alt} />
}

Logo.defaultProps = {
  alt: 'Logo - Kino Grażyna Strzelin',
}

Logo.propTypes = {
  alt: PropTypes.string,
}

export default Logo
