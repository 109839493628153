import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import Logo from '../logo'

import paths from '../../constants/paths'
import { forScreen } from '../../styles-global/styled-components/styled-responsive'

/* 
  NAVBAR from bootstrap 
  - hence as long as navbar is created with bootstrap - bootstrap classes are ok
  - custom styles with styled components
*/

const navbar = () => {
  const { mainNavPages } = paths

  return (
    <StyledNavbar expand="lg" variant="light">
      <Navbar.Brand as={Link} to="/" className="logoLink">
        <Logo />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <h2 className="visually-hidden">Main Navigation</h2>
          {/* DYNAMIC MAIN PAGES */}
          {mainNavPages.map(({ path, text }, index) => (
            <Nav.Link
              className="navLink"
              as={Link}
              to={path}
              key={index}
              activeClassName="navLink__opened"
            >
              {text}
            </Nav.Link>
          ))}
          <a
            href="http://epicentrum.hostingasp.pl/"
            className="btn btn-grazynaPrimary btn--uppercase navBtn"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kup bilet
          </a>
        </Nav>
      </Navbar.Collapse>
    </StyledNavbar>
  )
}

const StyledNavbar = styled(Navbar)`
  .navLink,
  .navBtn {
    transition: var(--buttonTransition);
    text-transform: uppercase;
    letter-spacing: 0.0625rem; //default ~1px
    padding: 1.25rem 0.625rem;
    margin-bottom: 2px;

    ${forScreen.lg`
      padding: 0.625rem;
      margin-left: 0.625rem;
      margin-botton: 0;
    `}

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .navLink {
    text-align: center;
    border-bottom: 2px solid transparent;

    &:hover {
      border-color: inherit;
    }
  }

  .navBtn {
    margin-left: 0;

    ${forScreen.lg`
      padding: 0.625rem;
        margin-left: 1.25rem;
    `}
  }

  .logoLink {
    width: 8rem;
    width: clamp(2rem, 35vw, 12rem);
    transition: transform 0.3s ease-out;
  }

  // OPENED PAGE LINK (bootstrap calls it 'active')
  // 3x class for higher specificity
  .navLink__opened.navLink__opened.navLink__opened {
    color: var(--navBarActiveColor);
    text-decoration: none;
    border-bottom: 2px solid;
    font-weight: 700;
  }
`

export default navbar
