import React from 'react'
import styled from 'styled-components'
import socialMedia from '../../constants/socialMedia'

/* Icon "button" - anchor for extr */
const IconButton = ({ option, isBigger = false }) => {
  if (option && socialMedia[option]) {
    const { link, accessibleText, Icon } = socialMedia[option]
    const iconSize = isBigger ? '3em' : '2em'

    return (
      <StyledIconButton href={link} isBigger={isBigger}>
        <Icon className="icon" size={iconSize} />
        <span className="visually-hidden">{accessibleText}</span>
      </StyledIconButton>
    )
  }
  // not found matching data for option
  console.warn(
    `Can't generate IconButton for option: ${option}. There's no matching option in socialMedia object`
  )
  return <></>
}

export default IconButton

const StyledIconButton = styled.a`
  margin: 0 ${({ isBigger }) => (isBigger ? '0.5rem' : '0.05rem')};

  svg.icon {
    color: var(--iconButtonColor);
    background-color: var(--iconButtonBackground);
    transition: var(--buttonTransition);
  }
  :hover {
    svg.icon {
      color: var(--iconButtonHoverColor);
      background-color: var(--iconButtonHoverBackground);
    }
  }
`
