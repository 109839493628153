import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import withLocation from '../withLocation'
import paths from '../../constants/paths'

import { filterRepertoireList } from '../../utils/filterRepertoire'

/* 
    Component that shows list of active movies (for footer) 
    
    It uses testDay (if given passed from withLocation) - user can specify it 
    passing queryParam e.g.: http://localhost:8000/?testDay=2020-09-11

    If testDay passed (as proper date ) movies that have endDate property older 
    than testDay are filtered out

    If not passed - function uses 'today' (date when user opens the page)
*/

const FooterReperoireList = ({ movies, testDay }) => {
  // filters out 'outdated' movies
  const moviesStillOnScreen = filterRepertoireList({ movies, testDay })

  return (
    <StyledFooterList>
      {moviesStillOnScreen.map(({ slug, titlePl }) => (
        <li key={slug.current}>
          <Link to={`/${paths.repertoireMovies.path}/${slug.current}`}>
            {titlePl}
          </Link>
        </li>
      ))}
    </StyledFooterList>
  )
}

export default withLocation(FooterReperoireList)

const StyledFooterList = styled.ul`
  list-style: none;
  padding-left: 0;
`
