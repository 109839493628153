import moment from 'moment'

/* Function returns moment day for today, if testDay given (as moment daye) it uses it as today */
export const getTodayDate = testDay => {
  // if testDay is not valid moment date - overwrite it to false
  if (!testDay || !testDay.isValid || !testDay.isValid()) {
    testDay = false
  }

  return testDay || moment().hour(0).minute(0).second(0)
}

/* 
    Function that filters movies list based on today date or testDay (if passed)
    If movie is still on the screen in given testDay (its onScreen.endDay hasn't passed - machineEndDate contains endDate in 'YYYY-MM-DD')
    these movies will be returned as 'active'

    In the default case - testDay is not passed and function checks if movie is active using 'today' date
*/
export const filterRepertoireList = ({ movies, testDay } = {}) => {
  if (!movies) {
    return []
  }

  const todayDate = getTodayDate(testDay)

  // filter movies still on the screen (its onScreen.endDay hasn't passed - machineEndDate contains endDate in 'YYYY-MM-DD')
  // these movies will be shown on the page
  const moviesActive = movies.filter(
    movie =>
      todayDate.isSameOrBefore(movie.onScreen?.machineEndDate, 'day') ||
      !movie.onScreen?.machineEndDate ||
      !movie.onScreen
  )

  // if testDay given - log (as a warning movies not shown because its onScreen.endDay has passed)
  if (testDay) {
    const moviesFilteredOut = movies
      .filter(
        movie =>
          movie.onScreen &&
          todayDate.isAfter(movie.onScreen?.machineEndDate, 'day')
      )
      .map(({ titlePl, onScreen }) => ({ titlePl, endDate: onScreen.endDate }))
    console.warn(
      `Projection days filtered out (are not active on ${testDay.format(
        'YYYY-MM-DD'
      )})`,
      moviesFilteredOut
    )
  }

  return moviesActive
}

/* 
  Function that filters projectionDays (in array of object for projectionDays) based on today date or testDay (if passed) 

  It returns array with projection days without inactive (past days)

  In the default case - testDay is not passed and function checks if movie is active using 'today' date
  otherwise todayDate gets the value from testDay
*/
export const filterMovieProjections = ({ projectionDays, testDay } = {}) => {
  if (!projectionDays) {
    return []
  }

  const todayDate = getTodayDate(testDay)

  // only for logging - when test day passed
  if (testDay) {
    const projectionDaysFilteredOut = projectionDays.filter(projectionDay =>
      todayDate.isAfter(projectionDay.day, 'day')
    )
    console.warn(
      `Projection days filtered out (are not active on ${testDay.format(
        'YYYY-MM-DD'
      )})`,
      projectionDaysFilteredOut
    )
  }

  return projectionDays.filter(projectionDay =>
    todayDate.isSameOrBefore(projectionDay.day, 'day')
  )
}
